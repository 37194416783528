import React from "react"
import { Link } from "gatsby"
import {
  Div,
  Text,
  Row,
  Col,
  Container,
  Anchor,
  // Icon
} from "atomize"

const footerLinks = {
  für: [
    {
      name: "Behörden",
      link: "/behoerden",
    },
    {
      name: "Kommunen",
      link: "/behoerden",
    },
    // {
    //   name: "Unternehmen",
    //   link: "/unternehmen",
    // },
  ],
  über: [
    {
      name: "Convaise",
      anchor: true,
      link: "https://www.convaise.com",
    },
    {
      name: "Fusionbase",
      anchor: true,
      link: "https://fusionbase.com/",
    },
    {
      name: "Behördenarzt",
      anchor: true,
      link: "https://www.behoerdenarzt.de/",
    },
  ],
}

// const mediaLinks = [
//   {
//     icon: "Github",
//     link: "https://github.com/proksh",
//   },
//   {
//     icon: "Behance",
//     link: "https://www.behance.net/prokshh90d1",
//   },
//   {
//     icon: "Dribbble",
//     link: "https://dribbble.com/proksh",
//   },
//   {
//     icon: "Twitter",
//     link: "https://twitter.com/proksh_luthra",
//   },
//   {
//     icon: "Linkedin",
//     link: "https://www.linkedin.com/in/prokshluthra/",
//   },
// ]

const Footer = () => {
  return (
    <Div
      tag="footer"
      p={{
        t: { xs: "3rem", md: "4rem" },
        b: { xs: "2rem", md: "2.5rem" },
        l: { xs: "0.5rem" },
        r: { xs: "0.5rem" },
      }}
      pos="relative"
    >
      <Container
        border={{ t: "1px solid" }}
        borderColor="gray300"
        p={{ t: { xs: "3rem", md: "4rem" }, b: { xs: "2rem", md: "2.5rem" } }}
      >
        <Div m={{ b: "4rem" }} d={{ xs: "block", md: "block" }}>
          <Row>
            <Col size={{ xs: 12, md: 4 }} offset={{ xs: 0, md: 1 }}>
              <Div
                p={{ l: { md: "1.5rem" }, b: { xs: "1.5rem", md: "0" } }}
                textColor="medium"
              >
                <Text
                  m={{ b: "1rem" }}
                  textColor="black"
                  textTransform="capitalize"
                  textWeight="500"
                >
                  Corona Navi
                </Text>
                <Text textColor="medium" d="block" textWeight="400">
                  Bereitgestellt durch ein Gemeinschaftsprojekt zwischen
                  Convaise, Fusionbase und Behördenarzt. <br />
                  Entwickelt und betrieben in Deutschland.
                </Text>
              </Div>
            </Col>
            {Object.keys(footerLinks).map((key, index) => {
              return (
                <Col
                  size={{ xs: 6, md: 2 }}
                  offset={{ xs: 0, md: index === 0 && 1 }}
                  key={index}
                >
                  <Div
                    p={{ l: { md: "2.5rem" }, b: { xs: "1.5rem", md: "0" } }}
                    textColor="medium"
                  >
                    <Text
                      m={{ b: "1rem" }}
                      textColor="black"
                      textTransform="capitalize"
                      textWeight="500"
                    >
                      {key}
                    </Text>
                    {footerLinks[key].map((link, i) => {
                      if (link.anchor) {
                        return (
                          <Anchor
                            m={{ b: "0.5rem" }}
                            textColor="medium"
                            hoverTextColor="info800"
                            d="block"
                            href={link.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            textWeight="400"
                            key={"link" + i}
                          >
                            {link.name}
                          </Anchor>
                        )
                      } else {
                        return (
                          <Link to={link.link} key={"link" + i}>
                            <Text
                              m={{ b: "0.5rem" }}
                              textColor="medium"
                              hoverTextColor="info800"
                            >
                              {link.name}
                            </Text>
                          </Link>
                        )
                      }
                    })}
                  </Div>
                </Col>
              )
            })}
          </Row>
        </Div>
        <Imprint />
      </Container>
    </Div>
  )
}

const Imprint = () => {
  return (
    <Div d="flex" align="center" justify="center" flexDir="column">
      <Div d="flex" align="center" justify="center">
        <Link to="/impressum">
          <Text m={{ b: "0.5rem" }} textColor="medium" hoverTextColor="info800">
            Impressum
          </Text>
        </Link>
        <Text
          m={{ b: "0.5rem" }}
          p={{ l: "5px", r: "5px" }}
          textColor="medium"
          hoverTextColor="info800"
        >
          {"  "}
        </Text>
        <Link to="/datenschutz">
          <Text m={{ b: "0.5rem" }} textColor="medium" hoverTextColor="info800">
            Datenschutzerklärung
          </Text>
        </Link>
      </Div>
      <Text
        tag="h2"
        textWeight="400"
        textSize="body"
        textAlign="center"
        m={{ b: "1rem" }}
      >
        © Convaise, Fusionbase & d.med-works 2021
      </Text>
    </Div>
  )
}

// const SocialMedia = () => {
//   return (
//     <Div d="flex" align="center" justify="center" flexDir="column">
//       <Text
//         tag="h2"
//         textWeight="400"
//         textSize="body"
//         textAlign="center"
//         m={{ b: "1rem" }}
//       >
//         Designed & Developed by Proksh Luthra
//       </Text>
//       <Div d="flex" align="center" justify="center">
//         {mediaLinks.map((link, index) => (
//           <Anchor href={link.link} target="_blank">
//             <Icon
//               name={link.icon}
//               size="20px"
//               hoverColor="info800"
//               m={{ r: index !== mediaLinks.length - 1 && "1rem" }}
//               cursor="pointer"
//             />
//           </Anchor>
//         ))}
//       </Div>
//     </Div>
//   )
// }

export default Footer
