import React from "react"
import { Link } from "gatsby"
import { Div, Container, Button, Anchor, Icon, Text } from "atomize"

import Loadable from "@loadable/component"

const ContactModal = Loadable(() => import("./Contact/contactModal"))

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showMobileHeaderMenu: false,
      shwoBanner: false,
      showContact: false,
    }
  }

  toggleHeaderMenu = value => {
    this.setState({ showMobileHeaderMenu: value })

    setTimeout(() => {
      window.scrollTo(0, window.scrollY + 1)
    }, 400)
  }

  render() {
    const { showMobileHeaderMenu, shwoBanner, showContact } = this.state

    return (
      <>
        {/* <Banner
          show={shwoBanner}
          close={() => this.setState({ shwoBanner: false })}
        /> */}
        <ContactModal
          onClose={() => this.setState({ showContact: false })}
          isOpen={showContact}
        />
        <Div
          tag="header"
          pos="fixed"
          top="0"
          transition
          left="0"
          right="0"
          zIndex="100"
          p={{ y: { xs: "0.5rem", md: "1rem" } }}
          border={{ b: "1px solid" }}
          borderColor="gray300"
        >
          <Div
            pos="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            bg="white"
            opacity="1"
            zIndex="-1"
          ></Div>
          <Container
            d="flex"
            align="center"
            justify="space-between"
            // border={{ b: "1px solid" }}
            // borderColor="gray300"
            // p={{ b: { xs: "1.5rem", md: "1rem" } }}
          >
            <Link to="/">
              <Div cursor="pointer" textColor="medium">
                {/* <Image
                src={logo}
                alt="atomize design system logo"
                h="18px"
                w="auto"
              /> */}
                <Text textSize="display1" tag="h1">
                  Corona Navi
                </Text>
              </Div>
            </Link>

            <Div
              d={{ xs: "flex", md: "none" }}
              flexDir="column"
              onClick={() => this.toggleHeaderMenu(!showMobileHeaderMenu)}
            >
              <Div
                h="2px"
                w="1rem"
                bg="black"
                rounded="lg"
                style={{
                  transform: `translateY(${
                    showMobileHeaderMenu ? "1" : "-2"
                  }px)rotate(${showMobileHeaderMenu ? "135" : "0"}deg)`,
                }}
                transition
              ></Div>
              <Div
                h="2px"
                w="1rem"
                bg="black"
                rounded="lg"
                style={{
                  transform: `translateY(${
                    showMobileHeaderMenu ? "-1" : "2"
                  }px)rotate(${showMobileHeaderMenu ? "45" : "0"}deg)`,
                }}
                transition
              ></Div>
            </Div>

            {/* Links for Desktop */}
            <Div
              d="flex"
              onClick={() => this.toggleHeaderMenu(false)}
              bg={{ xs: "white", md: "transparent" }}
              align={{ xs: "strech", md: "center" }}
              flexDir={{ xs: "column", md: "row" }}
              pos={{ xs: "absolute", md: "static" }}
              p={{
                t: { xs: "6rem", md: "0" },
                b: { xs: "2rem", md: "0" },
                x: { xs: "1.5rem", md: "0" },
              }}
              top="0"
              left="0"
              right="0"
              zIndex={{ xs: "-1", md: "0" }}
              shadow={{ xs: "4", md: "0" }}
              opacity={{
                xs: showMobileHeaderMenu ? "1" : "0",
                md: "1",
              }}
              transform={{
                xs: `translateY(${showMobileHeaderMenu ? "0" : "-100%"})`,
                md: "none",
              }}
              transition
            >
              {showMobileHeaderMenu ? (
                <Link to="/behoerden">
                  <Button
                    rounded="circle"
                    shadow="1"
                    hoverShadow="4"
                    w={{ xs: "100%", sm: "8.5rem" }}
                    p={{ x: "1.5rem" }}
                    m={{ b: "1.5rem " }}
                    justify="space-between"
                    bg="black700"
                    hoverBg="black500"
                  >
                    Für Behörden
                  </Button>
                </Link>
              ) : (
                <Link to="/behoerden">
                  <Text
                    m={{ r: "2rem", b: { xs: "1rem", md: "0" } }}
                    textWeight="500"
                    textColor="medium"
                    hoverTextColor="info800"
                  >
                    Für Behörden
                  </Text>
                </Link>
              )}
              {/* <Link to="/unternehmen">
                <Text
                  m={{ r: "2rem", b: { xs: "1rem", md: "0" } }}
                  textWeight="500"
                  textColor="medium"
                  hoverTextColor="info800"
                >
                  Für Unternehmen
                </Text>
              </Link> */}
              <Button
                rounded="circle"
                shadow="1"
                hoverShadow="4"
                w={{ xs: "100%", sm: "8.5rem" }}
                // h="3rem"
                p={{ x: "1.5rem" }}
                justify="space-between"
                bg="black700"
                hoverBg="black500"
                suffix={<Icon name="Email" size="20px" color="white" />}
                onClick={() => {
                  this.setState({ showContact: true })
                  typeof window !== "undefined" &&
                    window.plausible &&
                    window.plausible("Normal Contact")
                }}
              >
                Kontakt
              </Button>
            </Div>
          </Container>
        </Div>
      </>
    )
  }
}

export default Header

// const Banner = ({ show, close }) => (
//   <>
//     <Div
//       d="flex"
//       h={show ? { xs: "5.25rem", sm: "3.75rem" } : "0"}
//       opacity={show ? "1" : "0"}
//       overflow="hidden"
//       flexWrap="wrap"
//       justify="center"
//       align="center"
//       textColor="white"
//       pos="fixed"
//       bottom="0"
//       left="0"
//       right="0"
//       zIndex="110"
//       textAlign="center"
//       p="0.75rem"
//       style={{ background: "#DA552F" }}
//     >
//       <Anchor
//         href="https://www.producthunt.com/posts/atomize-react"
//         target="_blank"
//         d="flex"
//         flexWrap="wrap"
//         justify="center"
//         align="center"
//         textColor="white"
//         hoverTextColor="white"
//       >
//         We are trending on{" "}
//         {/* <Image
//           src={producthunt}
//           h="2.25rem"
//           w="auto"
//           cursor="pointer"
//           m={{ x: "0.5rem" }}
//         />{" "} */}
//         It will be a real help if you could support us 👉{" "}
//       </Anchor>
//       <Icon
//         name="Cross"
//         pos="absolute"
//         right="1rem"
//         top="50%"
//         transform="translateY(-50%)"
//         color="white"
//         size="16px"
//         onClick={close}
//       />
//     </Div>
//   </>
// )
